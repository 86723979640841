import { useEffect, useState } from 'react'

export const useCurrentYear = () => {
  const [year, setYear] = useState<number>()

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])

  return year ?? ''
}
