import styled from 'styled-components'
import { Image } from '@/elements/Image/NextImage'
import { Row, Column } from '@shipt/design-system-layouts'
import { LayoutContainer } from '@/styles/layout'
import { spacing } from '@/theme/tokens'
import { routes } from '@shared/constants/routes'
import { breakpoints } from '@/styles/media'
import { getFooterIcon } from '@/components/Footers/Footer/utils'
import {
  footerData,
  shopperPrivacy,
} from '@/components/Footers/MarketingFooter/constants'
import { type FooterContentType } from '@/components/Footers/MarketingFooter/types'
import { useCurrentYear } from '@/hooks/useCurrentYear'
import { ShiptStaticData } from '@/constants/shiptStaticData'
import { trackElementClicked } from '@/analytics/element'
import { screenSizes } from '@shipt/design-system-themes'
import { AppBadges } from '@/components/AppBadges'
import { Body } from '@shipt/design-system-typography'
import { ReactComponent as PrivacySelectionIcon } from '@/svg/privacy_icon.svg'
import { type JSX } from 'react'
import { objectStorageUrl } from '@/utils/objectStorageUrl'

type Props = {
  sections: JSX.Element
  children?: React.ReactNode
  contentType?: FooterContentType
}

export const FooterContent = ({
  sections,
  children,
  contentType = 'default',
}: Props) => {
  const images = ShiptStaticData.images
  const { socialLinks = [], privacy = [] } = footerData
  const currentYear = useCurrentYear()

  return (
    <FooterContentWrapper>
      <FooterLayout enableXLarge>
        <Column>
          <LogoAndSectionsWrapper>
            <LogoWrapper>
              <a href={routes.UNGATED_HOMEPAGE.url}>
                <Image
                  alt="home"
                  src={images.footer_logo}
                  width={100}
                  height={36}
                />
              </a>
            </LogoWrapper>
            {sections}
          </LogoAndSectionsWrapper>

          <SocialAndBadgesWrapper spacing="xl">
            <Row spacing="xl">
              {socialLinks.map(({ name, url }) => (
                <FooterSocialLink
                  aria-label={name}
                  target="_blank"
                  key={name}
                  href={url}
                  rel="noreferrer"
                  onClick={() => {
                    trackElementClicked({ link_name: name, next_location: url })
                  }}
                >
                  {getFooterIcon(name)}
                </FooterSocialLink>
              ))}
            </Row>
            <AppBadges
              openInNewTab
              isShopper={contentType === 'shopper'}
              displaySublocation="footer"
            />
            <div>
              <Image
                src={objectStorageUrl('newsweek2024.webp')}
                width={302}
                height={112}
                alt=""
              />
            </div>
          </SocialAndBadgesWrapper>
          <CopyrightText size="sm">
            &copy; {currentYear} Shipt, Inc. and its services are not
            necessarily affiliated or endorsed by the retailers on this site.
            Shipt does not operate in Alaska, Wyoming, and West Virginia.
          </CopyrightText>
          {children}
          <PrivacyPolicy>
            {(contentType === 'default' ? privacy : shopperPrivacy).map(
              (link, index) => (
                <LinksContainer key={link.name}>
                  {index !== 0 && <Line />}
                  {link.image && <PrivacySelectionIcon />}
                  <PrivacyLinks href={link.url}>{link.name}</PrivacyLinks>
                </LinksContainer>
              )
            )}
          </PrivacyPolicy>
        </Column>
      </FooterLayout>
    </FooterContentWrapper>
  )
}

const FooterContentWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.oatmeal};
`

const FooterLayout = styled(LayoutContainer)`
  @media ${screenSizes.max_mobile} {
    padding: ${spacing(0, 'xl', 52)};
    margin-left: auto;
    margin-right: auto;
  }

  @media ${screenSizes.tablet} {
    padding: ${spacing(0, 0, '4xl')};
  }
`

const LogoAndSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.8125rem;

  @media ${breakpoints.medium} {
    flex-direction: row;
    margin-top: 7.25rem;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 1rem;
`

const SocialAndBadgesWrapper = styled(Column)`
  margin-bottom: 2rem;
`

const CopyrightText = styled(Body)`
  color: ${({ theme }) => theme.gray800};
`

const PrivacyPolicy = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LinksContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const PrivacyLinks = styled.a`
  color: ${({ theme }) => theme.purple500};
  font-size: 0.8125rem;
  line-height: 1rem;
  text-decoration: underline;
  margin: 1rem 0.5rem 0 0;
`

const Line = styled.span`
  height: 1rem;
  background-color: ${({ theme }) => theme.gray300};
  width: 0.0625rem;
  margin-right: 0.5rem;
`

const FooterSocialLink = styled.a`
  color: ${({ theme }) => theme.gray900};
`
